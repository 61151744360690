.products-results {
  display: grid;
  flex-grow: 1;
  gap: 20px;
  grid-auto-rows: 360px;
  grid-template-columns: repeat(4, 1fr);
}

.product_list {
  height: 160px;
  width: 100%;
}

.product_list img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.product {
  align-items: center;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.product h2 {
  flex-grow: 1;
  font-size: 14px;
}

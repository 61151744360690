.content_categories {
  border: solid 1px black;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.content_categories label {
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 11px;
}

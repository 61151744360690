html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

.heder {
  align-items: center;
  border: solid 1px black;
  display: flex;
  height: 50px;
  justify-content: space-around;
  margin: auto;
  width: 80vw;
}

.input_search {
  border: solid 1px;
  border-radius: 18px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 50%;
}

.btn-search {
  margin-left: 15px;
}

.isfree {
  margin-top: 1px;
  width: 40%;
}

.isfree-details {
  margin-top: 1px;
  width: 8%;
}

p {
  text-align: center;
}

.conteiner {
  background: #d3d1d1;
  display: grid;
  grid-template-columns: 25% 1fr;
  margin: auto;
  width: 80vw;
}
